<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Danh sách thiết lập phí điều xe</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'm']" @shortkey="add()" @click="add()" class="md-raised md-primary btn-add">Thêm <span>m</span>ới<md-tooltip>Thêm mới (Ctrl + T)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="getData()" @click="getData()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="page-list-search">
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <md-field>
                                <label for="branchId">Chi nhánh</label>
                                <md-select v-model="branchId" name="branchId" id="branchId">
                                    <md-option v-for="b in branchs" :key="b.id" :value="b.id">{{b.branchName}}</md-option>
                                </md-select>
                            </md-field>
                        </div>
                    </div>
                </div>
                <div class="table-content">
                    <table class="data-table">
                        <thead>
                            <tr>
                                <th style="width:100px;">#</th>
                                <th class="left" style="width:15%;">Chi nhánh</th>
                                <th class="left" style="width:15%;">Loại hình</th>
                                <th class="left" style="width:15%;">Loại hàng</th>
                                <th class="left" style="width:10%;">Đơn vị</th>
                                <th class="left" style="width:17%;">Tên Phí</th>
                                <th class="left" style="width:10%;">Loại phí</th>
                                <th class="right" style="width:10%;">Số tiền</th>
                                <th style="width:10%;">Hành động</th>
                            </tr>
                        </thead>
                        <tbody v-if="loadding == true" style="height: 150px;">
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                        </tbody>
                        <tbody v-if="loadding == false && data.length == 0" style="height: 150px;">
                            <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                        </tbody>
                        <tbody v-if="loadding == false && data.length > 0">
                            <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                <td class="center">{{index + 1}}</td>
                                <td class="left">{{item.branch.branchName}}</td>
                                <td class="left">{{ getTypeOfTransport(item.typeOfTransport)}}</td>
                                <td class="left">
                                    {{ getGoodsType(item.goodsTypeId) }}
                                </td>
                                <td class="left">
                                    <span v-if="item.unit">{{item.unit.unitName}}</span>
                                </td>
                                <td class="left">{{item.fee.feeName}}</td>
                                <td>
                                    {{ getFeeType(item.type) }}
                                </td>
                                <td class="right">{{formatNumber(item.amount)}}</td>
                                <td class="action">
                                    <md-button @click="edit(item)" class="md-fab md-mini md-second">
                                        <md-icon>edit</md-icon>
                                        <md-tooltip>Sửa</md-tooltip>
                                    </md-button>
                                    <md-button v-on:click="confirmDelete(item.id)" class="md-fab md-mini md-plain">
                                        <md-icon>clear</md-icon>
                                        <md-tooltip>Xóa</md-tooltip>
                                    </md-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <crudForm ref="crudForm" @close="closeCrud"/>
    </div>
</template>
<script>
    import crudForm from './Crud.vue';
    import settingJobFeeService from '../../../api/settingJobFeeService';
    import branchService from '../../../api/branchService';
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex';

    export default {
        components: {
            crudForm
        },
        metaInfo: {
            title: 'Danh sách thiết lập phí điều xe'
        },
        data() {
            return {
               loadding: false,
               data: [],
               id: 0,
               branchId: 0,
               branchs: []
            }
        },
        created(){
            this.getBranchs();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            getBranchs(){
                this.setLoading(true);
                branchService.getAll().then((response) => {
                    if(response.statusCode == 200){
                        this.branchs = response.data;
                        this.branchId = response.data[0].id;
                        this.getData();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            closeCrud(action){
                this.getData();
                if(!action){
                    this.$refs.crudForm.close();
                }
            },

            getData(){
                this.loadding = true;
                settingJobFeeService.getAll(this.branchId).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            confirmDelete(id){
                this.$modal.show('dialog', {
                title: 'Thông báo',
                text: 'Bạn có chắc muốn xóa không?',
                buttons: [
                        {
                            title: 'Hủy',
                            handler: () => {
                                this.$modal.hide('dialog')
                            }
                        },
                        {
                            title: 'Xóa',
                            handler: () => {
                                this.del(id)
                            }
                       }
                ]
                })
            },

            del(id){
                this.$modal.hide('dialog');
                this.setLoading(true);
                settingJobFeeService.delete(id).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Xóa thành công");
                        this.getData();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            edit(obj){
                this.$refs.crudForm.open(obj.id);
            },

            add(){
                this.$refs.crudForm.open(0);
            },
        },
        watch: {
            'branchId': function () {
                this.getData();
            },
        }
    }

</script>
